import * as React from 'react';
import {
  TopToolbar,
  EditController,
  EditView,
  Loading,
  Error,
  useQueryWithStore,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { CompanyRecord } from '../interfaces/company-record.interface';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SendIcon from '@material-ui/icons/Send';
import BookIcon from '@material-ui/icons/Book';
import { makeStyles, Box } from '@material-ui/core';
import { CompanyEditForm } from '../components/company-edit-form';
import { CompanySubTabs } from '../components/company-subtabs';
import { HandbookRecord } from '../../handbook/interfaces/handbook-record.interface';

//TODO: The current react-admin-color-input is bringing from a fork
// Pleaes remove it once this PR is merged
// https://github.com/vascofg/react-admin-color-input/pull/5

const useStyle = makeStyles({
  toolbar: {
    justifyContent: 'space-between',
  },
  actionButton: {
    fontSize: '1em',
    textTransform: 'none',
  },
});

const Title = ({ record }: any) => {
  record = record as CompanyRecord;
  return <span>{record ? `${record?.name}` : ''}</span>;
};

const HandbookButton = ({ id }: { id: string }) => {
  const {
    loaded,
    error,
    data,
  }: {
    loaded: boolean;
    error: Error;
    data: HandbookRecord[];
  } = useQueryWithStore({
    type: 'getManyReference',
    resource: 'handbook',
    payload: {
      target: 'companyId',
      id,
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
    },
  });

  const classes = useStyle();

  if (!loaded) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Button
      className={classes.actionButton}
      component={Link}
      to={{
        pathname: `/handbook/${data[0].id}`,
      }}
      startIcon={<BookIcon />}
    >
      Handbook
    </Button>
  );
};

const CompanyEditActions = (props: any) => {
  const classes = useStyle();

  return (
    <TopToolbar className={classes.toolbar}>
      <Button
        className={classes.actionButton}
        component={Link}
        to={props.basePath}
        startIcon={<ArrowBackIosIcon />}
      >
        Companies
      </Button>
      <Box>
        <HandbookButton id={props.id} />
        <Button
          className={classes.actionButton}
          component={Link}
          to={{
            pathname: '/notification/sendCompany/create',
            state: { record: { companyId: props.id } },
          }}
          startIcon={<SendIcon />}
        >
          Notification
        </Button>
      </Box>
    </TopToolbar>
  );
};

export const CompanyEdit = (props: any) => {
  return (
    <EditController
      {...props}
      successMessage="messages.company.updated"
      undoable={false}
    >
      {(controllerProps: any) => {
        return (
          <React.Fragment>
            <EditView
              {...props}
              {...controllerProps}
              title={<Title />}
              actions={<CompanyEditActions id={props.id} />}
              undoable={false}
            >
              <CompanyEditForm {...props} />
            </EditView>
            <CompanySubTabs id={props.id} />
          </React.Fragment>
        );
      }}
    </EditController>
  );
};
