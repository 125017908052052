import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { DeleteWithConfirmButton, SaveButton, Toolbar } from 'react-admin';
import { UserRecord } from '../interfaces';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const UserEditToolbar = (props: { record?: UserRecord }) => {
  const { record } = props;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {record && record.id && (
        <DeleteWithConfirmButton
          undoable={false}
          redirect={`/company/${record?.companyId ?? ''}`}
          confirmTitle="messages.user.deleteConfirmTitle"
          confirmContent="messages.user.deleteConfirmContent"
        />
      )}
    </Toolbar>
  );
};
