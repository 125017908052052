import * as React from 'react';
import { CompanyResourceEditActionsToolbar } from '../../company/components/company-resource-edit-actions-toolbar';
import { CompanyNotification } from '../interfaces/company-notification.interface';

export const CompanyNotificationCreateActions = (props: {
  record: CompanyNotification;
}) => {
  const { record } = props;

  if (record) {
    return <CompanyResourceEditActionsToolbar record={record} />;
  } else {
    return <div />;
  }
};
