import * as React from 'react';
import {
  TextInput,
  CreateController,
  CreateView,
  SimpleForm,
  required,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { DetailsHeader } from '../../../components/details-header';
import { SubsectionRecord } from '../interfaces/subsection-record.interface';
import {
  SubsectionTitle,
  SubsectionEditActions,
  subsectionRichTextStyles,
} from '../components';

const SubsectionCreateForm = (props: { record?: SubsectionRecord }) => {
  const classes = subsectionRichTextStyles();
  const [title, setHeader] = React.useState('');
  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeader(e.target.value);
  };

  return (
    <SimpleForm {...props} redirect="edit">
      <DetailsHeader item={title} itemEmpty="New">
        Subsection -&nbsp;
      </DetailsHeader>
      <TextInput
        source="title"
        validate={[required()]}
        onChange={onTitleChange}
      />
      <RichTextInput
        label=""
        source="content"
        fullWidth
        formClassName={classes.richText}
      />
    </SimpleForm>
  );
};

export const SubsectionCreateView = (props: any) => {
  return (
    <CreateController {...props} successMessage="messages.subsection.created">
      {(controllerProps: any) => {
        const { record } = controllerProps;
        return (
          <CreateView
            {...controllerProps}
            title={<SubsectionTitle />}
            actions={<SubsectionEditActions record={record} />}
          >
            <SubsectionCreateForm />
          </CreateView>
        );
      }}
    </CreateController>
  );
};
