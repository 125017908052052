import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { makeStyles, Box } from '@material-ui/core';

interface DetailsHeaderOptions {
  children: string;
  item?: string;
  itemDefault?: string;
  itemEmpty?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '.5rem',
  },
}));

const DetailsTypography = (props: {
  children?: string;
  component?: string;
}) => {
  const classes = useStyles();
  return <Typography {...props} color="inherit" className={classes.root} />;
};

export const DetailsHeader = (props: DetailsHeaderOptions) => {
  const { children, item, itemDefault, itemEmpty } = props;
  let itemText = null;

  if (item || itemDefault) {
    itemText = (
      <DetailsTypography component="span">
        {item || itemDefault}
      </DetailsTypography>
    );
  } else if (itemEmpty) {
    itemText = <DetailsTypography component="i">{itemEmpty}</DetailsTypography>;
  }

  return (
    <Box>
      <DetailsTypography component="span">{children}</DetailsTypography>
      {itemText}
    </Box>
  );
};
