import * as React from 'react';
import { ExportButton, sanitizeListRestProps, TopToolbar } from 'react-admin';
import { Box } from '@material-ui/core';

export const CustomListTopToolbar = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  showFilter,
  maxResults,
  total,
  hasCreate,
  createButton,
  ...rest
}: any) => (
  <Box pl={2} pr={2}>
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate && createButton}
      {exporter !== false && (
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          exporter={exporter}
          maxResults={maxResults}
        />
      )}
    </TopToolbar>
  </Box>
);
