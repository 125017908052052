import * as React from 'react';
import { EditController, EditView } from 'react-admin';
import { InquiryTypeRecord } from '../interfaces/inquiry-type-record.interface';
import { InquiryTypeEditActions } from '../components/inquiry-type-edit-actions';
import { InquiryTypeEditForm } from '../components/inquiry-type-edit-form';

export const InquiryTypeEditView = (props: {
  id: string;
  data: InquiryTypeRecord;
}) => (
  <EditController
    {...props}
    undoable={false}
    successMessage="messages.inquiryType.updated"
  >
    {(controllerProps: any) => {
      const { record } = controllerProps;
      return (
        <EditView
          {...controllerProps}
          undoable={false}
          title={<span />}
          actions={<InquiryTypeEditActions record={record} />}
        >
          <InquiryTypeEditForm id={props.id} />
        </EditView>
      );
    }}
  </EditController>
);
