import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import domainMessages from './domain';

const messages = {
  en: { ...englishMessages, ...domainMessages },
};

// overrides
messages.en.ra.auth.username = 'Email Address';

const i18nProvider = polyglotI18nProvider(() => messages['en']);

export default i18nProvider;
