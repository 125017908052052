import { Avatar, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { CompanyRecord } from '../modules/company/interfaces/company-record.interface';

interface CompanyAvatarProps {
  record: CompanyRecord;
  size?: 'medium' | 'large';
}

const useAvatarStyle = (color?: string) =>
  makeStyles(theme => {
    const base = {
      color: color ?? 'inherit',
      backgroundColor: color ? theme.palette.getContrastText(color) : 'inherit',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: color ?? 'inherit',
      '& img': {
        height: 'auto',
      },
    };

    return {
      medium: {
        ...base,
      },
      large: {
        ...base,
        fontSize: '5em',
        width: '2em',
        height: 'auto',
      },
    };
  });

export const CompanyAvatar = (props: CompanyAvatarProps) => {
  const { record, size = 'medium' } = props;

  const avatarClasses = useAvatarStyle(record?.viewMeta?.primaryColor)();

  const logoUrl =
    record?.viewMeta?.logoUrl &&
    Array.isArray(record.viewMeta.logoUrl) &&
    record.viewMeta.logoUrl.length
      ? process.env.REACT_APP_API_URL +
        '/uploads/' +
        record.viewMeta.logoUrl[0].url
      : null;

  if (logoUrl) {
    return (
      <Avatar
        alt={record.name}
        className={avatarClasses[size]}
        src={logoUrl}
        variant="square"
      />
    );
  } else {
    return (
      <Avatar
        alt={record.name}
        className={avatarClasses[size]}
        variant="square"
      >
        {record.name.slice(0, 1)}
      </Avatar>
    );
  }
};
