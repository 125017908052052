import React from 'react';
import { Box } from '@material-ui/core';

type Props = {
  space?: number;
};

export const Spacer: React.FC<Props> = props => {
  const space = props.space || 20;

  return <Box height={space} width={space} />;
};
