import {
  CondOperator,
  QueryFilter,
  QuerySort,
  RequestQueryBuilder,
} from '@nestjsx/crud-request';
import omitBy from 'lodash.omitby';
import { DataProvider } from 'ra-core';
import { fetchUtils } from 'react-admin';
import { CreateParams } from 'ra-core';
import { OrderButtonsDirection } from '../components/order-buttons';

/**
 * Maps react-admin queries to a nestjsx/crud powered REST API
 *
 * @see https://github.com/nestjsx/crud
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import crudProvider from 'ra-data-nestjsx-crud';
 *
 * import { PostList } from './posts';
 *
 * const dataProvider = crudProvider('http://localhost:3000');
 * const App = () => (
 *     <Admin dataProvider={dataProvider}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

export interface CrudDataProviderOptions {
  alwaysPaginate?: boolean;
}

interface CrudDataProviderResponse {
  [key: string]: Record<string, any>;
}

interface CrudDataProviderMoveFilter {
  [key: string]: Record<string, any>;
}

const countDiff = (
  o1: Record<string, any>,
  o2: Record<string, any>
): Record<string, any> => omitBy(o1, (v: any, k: any) => o2[k] === v);

const composeFilter = (paramsFilter: any): QueryFilter[] => {
  if (
    paramsFilter === '' ||
    (typeof paramsFilter.q !== 'undefined' && paramsFilter.q === '')
  ) {
    paramsFilter = {};
  }

  const flatFilter = fetchUtils.flattenObject(paramsFilter);
  return Object.keys(flatFilter).map(key => {
    const splitKey = key.split('||');

    let field = splitKey[0];
    let ops = splitKey[1];
    if (!ops) {
      if (
        typeof flatFilter[key] === 'number' ||
        flatFilter[key].match(/^\d+$/)
      ) {
        ops = CondOperator.EQUALS;
      } else {
        ops = CondOperator.CONTAINS;
      }
    }

    if (field.startsWith('_') && field.includes('.')) {
      field = field.split(/\.(.+)/)[1];
    }
    return { field, operator: ops, value: flatFilter[key] } as QueryFilter;
  });
};

export default (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  options?: CrudDataProviderOptions
): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;

    const query = RequestQueryBuilder.create({
      filter: composeFilter(params.filter),
    })
      .setLimit(perPage)
      .setPage(page)
      .sortBy(params.sort as QuerySort)
      .setOffset((page - 1) * perPage)
      .query();

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ json }: CrudDataProviderResponse) => ({
      data: json.data,
      total: json.total,
    }));
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(
      ({ json }: CrudDataProviderResponse) => ({
        data: json,
      })
    ),

  getMany: (resource, params) => {
    const query = RequestQueryBuilder.create()
      .setFilter({
        field: 'id',
        operator: CondOperator.IN,
        value: `${params.ids}`,
      })
      .query();

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ json }: CrudDataProviderResponse) => ({
      data: options?.alwaysPaginate === true ? json.data : json,
    }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const filter: QueryFilter[] = composeFilter(params.filter);

    filter.push({
      field: params.target,
      operator: CondOperator.EQUALS,
      value: params.id,
    });

    const query = RequestQueryBuilder.create({
      filter,
    })
      .sortBy(params.sort as QuerySort)
      .setLimit(perPage)
      .setOffset((page - 1) * perPage)
      .query();

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ json }: CrudDataProviderResponse) => ({
      data: json.data,
      total: json.total,
    }));
  },

  update: (resource, params) => {
    // no need to send all fields, only updated fields are enough
    const data = params?.previousData
      ? countDiff(params.data, params.previousData)
      : params.data;
    // call the client
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    }).then(({ json }: CrudDataProviderResponse) => ({ data: json }));
  },

  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    ).then(responses => ({
      data: responses.map(({ json }) => json),
    })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }: CrudDataProviderResponse) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }: CrudDataProviderResponse) => ({
      data: { ...json, id: params.id },
    })),

  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json) })),

  move: (
    resource: string,
    params: {
      id: number | string;
      direction: OrderButtonsDirection;
    }
  ) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}/move`, {
      method: 'PATCH',
      body: JSON.stringify({
        direction: params.direction,
      }),
    }).then(({ json }: CrudDataProviderResponse) => ({ data: json }));
  },

  /**
   * This create returns exactly what the api returns.
   *
   * @param resource
   * @param params
   */
  createRaw: (resource: string, params: CreateParams) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }: CrudDataProviderResponse) => {
      return {
        data: json,
      };
    }),
});
