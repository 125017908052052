import * as React from 'react';
import { SimpleForm, TextInput, required } from 'react-admin';
import { DetailsHeader } from '../../../components/details-header';
import { DepartmentEditToolbar } from './department-edit-toolbar';
import { DepartmentRecord } from '../interfaces/department-record.interface';
import { Spacer } from '../../../components/spacer';
import { Box } from '@material-ui/core';

export const DepartmentEditForm = (
  props: { record: DepartmentRecord } & any
) => {
  const { record } = props;
  const [title, setHeader] = React.useState('');
  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeader(e.target.value);
  };

  return (
    <SimpleForm
      {...props}
      toolbar={<DepartmentEditToolbar record={record} />}
      redirect="edit"
    >
      <div style={{ width: '100%' }}>
        <Box p={2} flex={1}>
          <DetailsHeader
            item={title}
            itemDefault={record?.name}
            itemEmpty="New"
          >
            Department -&nbsp;
          </DetailsHeader>
          <Spacer />
          <TextInput
            source="name"
            validate={[required()]}
            onChange={onTitleChange}
          />
        </Box>
      </div>
    </SimpleForm>
  );
};
