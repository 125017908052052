import * as React from 'react';
import { CreateController, CreateView } from 'react-admin';
import { UserEditActions, UserEditForm } from '../components';
import { UserRecord } from '../interfaces/user-record.interface';

export const UserCreateView = (props: { id: string; data: UserRecord }) => (
  <CreateController
    {...props}
    undoable={false}
    successMessage="messages.user.created"
  >
    {(controllerProps: any) => {
      const { record } = controllerProps;

      return (
        <CreateView
          {...controllerProps}
          title={<span />}
          actions={<UserEditActions record={record} />}
        >
          <UserEditForm id={props.id} />
        </CreateView>
      );
    }}
  </CreateController>
);
