import * as React from 'react';
import { TextInput, Edit, SimpleForm } from 'react-admin';
import { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import { HandbookTitle } from '../components/handbook-title';
import { DetailsHeader } from '../../../components/details-header';
import { HandbookEditActions } from '../components/handbook-edit-actions';
import { HandbookEditToolbar } from '../components/handbook-edit-toolbar';
import { HandbookSectionList } from '../components/handbook-section-list';

export const HandbookEditView = (props: any) => {
  return (
    <Fragment>
      <Edit
        {...props}
        title={<HandbookTitle />}
        actions={<HandbookEditActions />}
        undoable={false}
        successMessage="messages.handbook.updated"
      >
        <SimpleForm toolbar={<HandbookEditToolbar />} redirect="edit">
          <React.Fragment>
            <DetailsHeader>Handbook Details</DetailsHeader>
          </React.Fragment>
          <div style={{ width: '100%' }}>
            <Box display="flex" flexWrap="wrap" flexDirection="row">
              <Box p={1} flexGrow={1}>
                <TextInput source="title" fullWidth />
              </Box>
              <Box p={1} flexGrow={1}>
                <TextInput source="versionName" fullWidth />
              </Box>
            </Box>
          </div>
        </SimpleForm>
      </Edit>
      <HandbookSectionList id={props.id} />
    </Fragment>
  );
};
