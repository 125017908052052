import * as React from 'react';
import { SaveButton, Toolbar } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const HandbookEditToolbar = (props: any) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
);
