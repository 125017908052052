import * as React from 'react';
import { TextInput, Create, SimpleForm } from 'react-admin';
import { DetailsHeader } from '../../../components/details-header';

export const CompanyCreateView = (props: any) => (
  <Create {...props} redirect="list" successMessage="messages.company.created">
    <SimpleForm>
      <React.Fragment>
        <DetailsHeader>Company Details</DetailsHeader>
      </React.Fragment>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
