import * as React from 'react';

import {
  Datagrid,
  List,
  Filter,
  TextField,
  TextInput,
  EditButton,
  SimpleList,
  ReferenceManyField,
  SingleFieldList,
} from 'react-admin';

import { useMediaQuery } from '@material-ui/core';
import { ColorField } from 'react-admin-color-input';
import { CompanyRecord } from '../interfaces/company-record.interface';
import { CompanyAvatar } from '../../../components/company-avatar';

const CompanyFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="name" alwaysOn />
    </Filter>
  );
};

const CompanyDatagrid = (props: any) => {
  return (
    <Datagrid {...props} rowClick="edit">
      <CompanyAvatar {...props} />
      <TextField source="name" />
      <TextField source="welcomeText" />
      <ReferenceManyField
        label="Handbook"
        reference="handbook"
        target="companyId"
      >
        <SingleFieldList>
          <TextField source="title" />
        </SingleFieldList>
      </ReferenceManyField>
      <ColorField source="viewMeta.primaryColor" sortable={false} />
      <ColorField source="viewMeta.secondaryColor" sortable={false} />
      <EditButton label="" />
    </Datagrid>
  );
};

export const CompanyListView = (props: any) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  return (
    <React.Fragment>
      <List {...props} filters={<CompanyFilter />}>
        {isSmall ? (
          <SimpleList
            leftAvatar={(record: CompanyRecord) => {
              return <CompanyAvatar record={record} />;
            }}
            primaryText={(record: CompanyRecord) => record?.name}
            secondaryText={(record: CompanyRecord) => record?.welcomeText}
          />
        ) : (
          <CompanyDatagrid />
        )}
      </List>
    </React.Fragment>
  );
};
