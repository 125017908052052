import * as React from 'react';
import { CreateController, CreateView } from 'react-admin';
import { CompanyNotification } from '../interfaces/company-notification.interface';
import { CompanyNotificationCreateForm } from '../components/company-notification-create-form';
import { CompanyNotificationCreateActions } from '../components/company-notification-create-actions';

export const CompanyNotificationCreateView = (props: {
  record: CompanyNotification;
}) => {
  return (
    <CreateController
      {...props}
      undoable={false}
      successMessage="messages.notification.created"
    >
      {(controllerProps: any) => {
        const { record } = controllerProps;
        return (
          <CreateView
            {...controllerProps}
            title={<span />}
            actions={<CompanyNotificationCreateActions record={record} />}
          >
            <CompanyNotificationCreateForm record={props.record} />
          </CreateView>
        );
      }}
    </CreateController>
  );
};
