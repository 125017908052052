import * as React from 'react';
import { SectionRecord } from '../interfaces/section-record.interface';
import { HandbookResourceEditActionsToolbar } from '../../handbook/components/handbook-resource-edit-actions-toolbar';

export const SectionEditActions = (props: { record: SectionRecord }) => {
  const { record } = props;

  if (record) {
    return <HandbookResourceEditActionsToolbar record={record} />;
  } else {
    return <div />;
  }
};
