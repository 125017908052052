import * as React from 'react';
import {
  DateField,
  CreateButton,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { SubListHeader } from '../../../components/sub-list-header';
import { CustomListTopToolbar } from '../../../components/custom-list-top-toolbar';
import { SubListContent } from '../../../components/sub-list-content';
import { OrderButtons } from '../../../components/order-buttons';

const useStyles = makeStyles({
  list: {
    marginTop: '1rem',
  },
});

const SubsectionTitle = () => <span />;

const SubsectionFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="title" alwaysOn />
    </Filter>
  );
};

const CreateSubsectionButton = (props: any) => {
  return (
    <CreateButton
      component={Link}
      to={{
        pathname: '/subsection/create',
        state: { record: { sectionId: props.sectionId } },
      }}
    />
  );
};

const ListEmpty = (props: any) => (
  <Box textAlign="center" m={1}>
    <Typography>No subsections have been created for this section.</Typography>
    <CreateSubsectionButton {...props} />
  </Box>
);

export const SectionSubsectionList = (props: any) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      basePath="/subsection"
      resource="subsection"
      hasCreate={true}
      filter={{ 'sectionId||$eq': props.id }}
      className={classes.list}
      title={<SubsectionTitle />}
      sort={{ field: 'sortPriority', order: 'ASC' }}
      actions={
        <CustomListTopToolbar
          createButton={<CreateSubsectionButton sectionId={props.id} />}
        />
      }
      filters={<SubsectionFilter />}
      empty={<ListEmpty sectionId={props.id} />}
    >
      <SubListContent
        rowClick="edit"
        header={<SubListHeader>Subsections</SubListHeader>}
      >
        <TextField source="title" showTime sortable={false} />
        <DateField source="updatedAt" showTime sortable={false} />
        <OrderButtons source="sortPriority" />
        <EditButton label="" textAlign="right" />
      </SubListContent>
    </List>
  );
};
