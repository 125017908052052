import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslate } from 'react-admin';
import { CompanyUserList } from './company-user-list';
import { CompanyDepartmentList } from './company-department-list';
import { CompanyInquiryTypeList } from './company-inquiry-type-list';
import { useHistory } from 'react-router';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`company-subtabpanel-${index}`}
      aria-labelledby={`company-subtab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `company-subtab-${index}`,
    'aria-controls': `company-subtabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '2rem',
    backgroundColor: theme.palette.background.paper,
  },
}));

export function CompanySubTabs(props: { id: string }) {
  const classes = useStyles();
  const translate = useTranslate();
  const [value, setValue] = React.useState(0);
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    //TODO: This is to clear the filter so it wont be applied to other List in the same page
    // maybe there is a way to do that using the react admin component?
    history.push({ search: '' });
    
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Company Resources"
        >
          <Tab
            label={translate('resources.user.name', { smart_count: 3 })}
            {...a11yProps(0)}
          />
          <Tab
            label={translate('resources.department.name', { smart_count: 3 })}
            {...a11yProps(1)}
          />
          <Tab
            label={translate('resources.inquiryType.name', { smart_count: 3 })}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <CompanyUserList id={props.id} />
      </TabPanel>
      <TabPanel value={value} index={1} >
        <CompanyDepartmentList id={props.id} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CompanyInquiryTypeList id={props.id} />
      </TabPanel>
    </div>
  );
}
