import * as React from 'react';
import {
  CreateButton,
  BooleanField,
  BooleanInput,
  EditButton,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { SubListContent } from '../../../components/sub-list-content';
import { CustomListTopToolbar } from '../../../components/custom-list-top-toolbar';
import BulkDeleteUndoableButton from '../../../components/bulk-delete-undoable-button';

const useStyles = makeStyles({
  root: {
    marginTop: '1rem',
  },
});

const UserFilter = (props: any) => {
  return (
    <Box pl={2} pr={2}>
      <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn />
        <TextInput label="First Name" source="firstName" />
        <TextInput label="Last Name" source="lastName" />
        <BooleanInput
          label="Acknowledged?"
          source="acknowledged||$eq"
          defaultValue={true}
        />
      </Filter>
    </Box>
  );
};

const CreateUserButton = (props: any) => {
  return (
    <CreateButton
      component={Link}
      to={{
        pathname: '/user/create',
        state: { record: { companyId: props.companyId } },
      }}
    >
      Add New User
    </CreateButton>
  );
};

const ListEmpty = (props: any) => (
  <Box textAlign="center" p={5}>
    <Typography>No users have been created for this company.</Typography>
    <CreateUserButton {...props} />
  </Box>
);

export const CompanyUserList = (props: any) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      basePath="/user"
      resource="user"
      hasCreate={true}
      filter={{ 'companyId||$eq': props.id }}
      className={classes.root}
      title={<span />}
      actions={
        <CustomListTopToolbar
          createButton={<CreateUserButton companyId={props.id} />}
        />
      }
      filters={<UserFilter />}
      empty={<ListEmpty companyId={props.id} />}
      bulkActionButtons={<BulkDeleteUndoableButton />}
    >
      <SubListContent rowClick="edit">
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <BooleanField source="acknowledged" />
        <ReferenceField
          label="Department"
          source="departmentId"
          reference="department"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton label="" textAlign="right" />
      </SubListContent>
    </List>
  );
};
