import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { DeleteWithConfirmButton, SaveButton, Toolbar } from 'react-admin';
import { SectionRecord } from '../interfaces/section-record.interface';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const SectionEditToolbar = (props: { record?: SectionRecord }) => {
  const { record } = props;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteWithConfirmButton
        undoable={false}
        redirect={`/handbook/${record?.handbookId ?? ''}`}
        confirmTitle="messages.section.deleteConfirmTitle"
        confirmContent="messages.section.deleteConfirmContent"
      />
    </Toolbar>
  );
};
