import * as React from 'react';
import { SubsectionEditActionsToolbar } from './subsection-edit-actions-toolbar';
import { SubsectionRecord } from '../interfaces/subsection-record.interface';

export const SubsectionEditActions = (props: { record?: SubsectionRecord }) => {
  const { record } = props;

  if (record) {
    return <SubsectionEditActionsToolbar record={record} />;
  } else {
    return null;
  }
};
