import React, { useEffect } from 'react';

import { useNotify, Notification, useRedirect } from 'react-admin';
import { useForm } from 'react-hook-form';
import { Login } from 'react-admin';
import { withStyles } from '@material-ui/styles';
import { loginStyles, formStyles } from './styles';
import { TextField, Button, Typography } from '@material-ui/core';
import * as yup from 'yup';
import { AuthService } from '../../libs/sdk';
import { Spacer } from '../../components/spacer';
import { useParams } from 'react-router-dom';
import CustomNotification from '../../components/CustomNotification';

const schema = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Passwords must match')
    .required('Password confirm is required'),
});

const ResetPasswordScreen: React.FC<any> = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { token } = useParams();
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
  });

  const checkResetToken = async (token: string) => {
    try {
      await AuthService.authResetCheckToken({ token });
    } catch (err) {
      notify(err.response.data.message, 'warning');
    }
  };

  useEffect(() => {
    checkResetToken(token);
    // eslint-disable-next-line
  }, [token]);

  const onSubmit = async (data: Record<string, any>) => {
    try {
      const { password } = data;
      await AuthService.authResetUpdatePassword({ token, body: { password } });
      notify('Password has been updated sucessfully.');
      redirect('/login');
    } catch (err) {
      let message = err?.response?.data?.message;
      if (!message) message = 'Could not reset your password';
      notify(message, 'warning', {}, false, 10000 );
      console.error(err);
    }
  };

  return (
    <Login {...props}  notification={CustomNotification}>
      <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
        Create a new password
      </Typography>

      <Spacer />

      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          id="password"
          name="password"
          type="password"
          error={!!errors.password}
          inputRef={register}
          helperText={errors.password && errors.password.message}
          label="Password"
          fullWidth
        />
        <Spacer />
        <TextField
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          error={!!errors.confirmPassword}
          inputRef={register}
          helperText={errors.confirmPassword && errors.confirmPassword.message}
          label="Confirm Password"
          fullWidth
        />
        <Spacer space={40} />
        <Button
          style={formStyles.button}
          type="submit"
          variant="contained"
          color="primary"
        >
          Send
        </Button>
      </form>
      <Notification />
    </Login>
  );
};

export default withStyles(loginStyles)(ResetPasswordScreen);
