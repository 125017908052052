import * as React from 'react';
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  RadioButtonGroupInput,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { DetailsHeader } from '../../../components/details-header';
import { NotificationCreateToolbar } from '../../../components/notification-create-toolbar';
import { CompanyNotification } from '../interfaces/company-notification.interface';
import { Card, CardContent } from '@material-ui/core';
import { Spacer } from '../../../components/spacer';

export const CompanyNotificationCreateForm = (
  props: { record: CompanyNotification } & any
) => {
  const { record } = props;

  return (
    <SimpleForm
      {...props}
      toolbar={<NotificationCreateToolbar record={record} />}
      redirect={() => `/company/${record.companyId}`}
    >
      <DetailsHeader>Push Notification</DetailsHeader>
      <Spacer />
      <div style={{ width: '100%', display: 'flex' }}>
        <Box flex="1" flexDirection="column">
          <TextInput source="title" validate={[required()]} fullWidth />
          <TextInput
            source="message"
            multiline
            validate={[required()]}
            fullWidth
            rows={5}
          />
        </Box>
        <Spacer />
        <Box flex="1">
          <Card>
            <CardContent>
              <Typography variant="h6">Segmentation (Optional)</Typography>
              <ReferenceInput
                source="departmentId"
                reference="department"
                filter={{ 'companyId||$eq': record.companyId }}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth
                resettable
                emptyText="Notify All Departments"
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <RadioButtonGroupInput
                label="Acknowledgment Status"
                source="userAcknowledged"
                fullWidth
                optionValue="_v"
                initialValue={null}
                choices={[
                  {
                    id: 'all',
                    name: 'All',
                    _v: null,
                  },
                  {
                    id: 'yes',
                    name: 'Yes',
                    _v: true,
                  },
                  {
                    id: 'no',
                    name: 'No',
                    _v: false,
                  },
                ]}
              />
            </CardContent>
          </Card>
        </Box>
      </div>
    </SimpleForm>
  );
};
