import * as React from 'react';
import { SimpleForm, TextInput, required } from 'react-admin';
import Box from '@material-ui/core/Box';
import { DetailsHeader } from '../../../components/details-header';
import { NotificationCreateToolbar } from '../../../components/notification-create-toolbar';
import { HandbookNotification } from '../interfaces/handbook-notification.interface';

export const HandbookNotificationCreateForm = (
  props: { record: HandbookNotification } & any
) => {
  const { record } = props;

  return (
    <SimpleForm
      {...props}
      toolbar={<NotificationCreateToolbar record={record} />}
      redirect={() => `/handbook/${record.handbookId}`}
    >
      <React.Fragment>
        <DetailsHeader>Force Acknowledgement</DetailsHeader>
      </React.Fragment>
      <div style={{ width: '100%' }}>
        <Box display="flex" flexWrap="wrap" flexDirection="row">
          <Box flexGrow={1} p={1}>
            <TextInput source="title" validate={[required()]} fullWidth />
          </Box>
          <Box flexGrow={1} p={1}>
            <TextInput
              source="message"
              multiline
              validate={[required()]}
              fullWidth
              rows={5}
            />
          </Box>
        </Box>
      </div>
    </SimpleForm>
  );
};
