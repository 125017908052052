import logo from '../../assets/logo.png';

export const loginStyles = {
  main: { background: '#324158' },
  avatar: {
    background: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: 120,
    height: 120,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  card: { padding: 40 },
  icon: { display: 'none' },
};

export const formStyles = {
  button: {
    backgroundColor: '#F3BD5D',
    color: 'black',
    width: '100%',
    '&:hover': {
      backgroundColor: '#F3BD5D',
    },
  },
};
