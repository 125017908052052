import React, { SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface OrderButtonsRecord {
  [key: string]: Record<string, unknown>;
}

interface OrderButtonsProps {
  record: { id: string | number } & OrderButtonsRecord;
  resource: string;
  source: string;
}

interface OrderButtonsStateParams {
  order: string;
  sort: string;
  filter: object;
}

export enum OrderButtonsDirection {
  Up = 'up',
  Down = 'down',
}

export const OrderButtons = (props: any) => {
  const { record, resource, source } = props;
  
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const { order, sort, filter }: OrderButtonsStateParams = useSelector(
    (state: any) => state.admin.resources[resource].list.params
  );
  
  const filterLength = filter ? Object.keys(filter).length : 0;

  const move = (direction: OrderButtonsDirection) => {
    dataProvider
      .move(resource, { id: record.id, direction })
      .then(() => refresh())
      .catch((error: Error) => notify(error.message, 'warning'));
  };

  const handleUp = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    move(OrderButtonsDirection.Up);
  };

  const handleDown = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    move(OrderButtonsDirection.Down);
  };

  const isValidSource = source === sort || sort === null;

  return (
    <ButtonGroup
      size="small"
      disabled={
        // Default value its sorted
        !isValidSource ||
        order === 'DESC' ||
        !record[source] ||
        filterLength > 0
      }
    >
      <Button onClick={handleUp} title="Move up">
        <KeyboardArrowUpIcon />
      </Button>
      <Button onClick={handleDown} title="Move down">
        <KeyboardArrowDownIcon />
      </Button>
    </ButtonGroup>
  );
};

OrderButtons.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
};
