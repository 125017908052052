import * as React from 'react';
import { EditController, EditView } from 'react-admin';
import { DepartmentRecord } from '../interfaces/department-record.interface';
import { DepartmentEditActions } from '../components/department-edit-actions';
import { DepartmentEditForm } from '../components/department-edit-form';

export const DepartmentEditView = (props: {
  id: string;
  data: DepartmentRecord;
}) => (
  <EditController
    {...props}
    undoable={false}
    successMessage="messages.department.updated"
  >
    {(controllerProps: any) => {
      const { record } = controllerProps;
      return (
        <EditView
          {...controllerProps}
          undoable={false}
          title={<span />}
          actions={<DepartmentEditActions record={record} />}
        >
          <DepartmentEditForm id={props.id} />
        </EditView>
      );
    }}
  </EditController>
);
