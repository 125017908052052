import * as React from 'react';
import { CreateController, CreateView } from 'react-admin';
import { HandbookNotification } from '../interfaces/handbook-notification.interface';
import { HandbookNotificationCreateForm } from '../components/handbook-notification-create-form';
import { HandbookNotificationCreateActions } from '../components/handbook-notification-create-actions';

export const HandbookNotificationCreateView = (props: {
  record: HandbookNotification;
}) => {
  return (
    <CreateController
      {...props}
      undoable={false}
      successMessage="messages.forceAck.created"
    >
      {(controllerProps: any) => {
        const { record } = controllerProps;
        return (
          <CreateView
            {...controllerProps}
            title={<span />}
            actions={<HandbookNotificationCreateActions record={record} />}
          >
            <HandbookNotificationCreateForm record={props.record} />
          </CreateView>
        );
      }}
    </CreateController>
  );
};
