export default {
  messages: {
    company: {
      created: 'Company details saved.',
      updated: 'Company details saved.',
    },
    department: {
      created: 'Department details saved.',
      updated: 'Department details saved.',
      deleteConfirmTitle: 'Are you sure you want to delete this Department?',
      deleteConfirmContent: 'This action cannot be undone.',
    },
    user: {
      created: 'User details saved.',
      updated: 'User details saved.',
      deleteConfirmTitle: 'Are you sure you want to delete this User?',
      deleteConfirmContent: 'This action cannot be undone.',
    },
    handbook: {
      updated: 'Handbook details saved.',
    },
    section: {
      created: 'Section details saved.',
      updated: 'Section details saved.',
      deleteConfirmTitle: 'Are you sure you want to delete this Section?',
      deleteConfirmContent: 'This action cannot be undone.',
    },
    subsection: {
      created: 'Subsection details saved.',
      updated: 'Subsection details saved.',
      deleteConfirmTitle: 'Are you sure you want to delete this Subsection?',
      deleteConfirmContent: 'This action cannot be undone.',
    },
    notification: {
      created: 'Notification sent.',
    },
    forceAck: {
      created: 'Force Acknowledgement sent',
    },
    inquiryType: {
      created: 'Inquiry Type details saved.',
      updated: 'Inquiry Type details saved.',
      deleteConfirmTitle: 'Are you sure you want to delete this Inquiry Type?',
      deleteConfirmContent: 'This action cannot be undone.',
    },
  },
  buttons: {
    auth: {
      resetPassword: 'Reset Password',
    },
  },
  resources: {
    company: {
      name: 'Company |||| Companies',
      fields: {
        name: 'Name',
        welcomeText: 'Welcome Text',
        viewMeta: {
          logoUrl: 'Logo',
          videoUrl: 'Welcome Video Link ',
          primaryColor: 'Primary Color',
          secondaryColor: 'Secondary Color',
        },
        updatedAt: 'Updated',
        createdAt: 'Created',
      },
    },
    user: {
      name: 'User |||| Users',
      fields: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        acknowledged: 'Acknowledged',
        updatedAt: 'Updated',
        createdAt: 'Created',
      },
    },
    department: {
      name: 'Department |||| Departments',
      fields: {
        name: 'Name',
        updatedAt: 'Updated',
        createdAt: 'Created',
      },
    },
    handbook: {
      name: 'Handbook |||| Handbooks',
      fields: {
        title: 'Title',
        versionName: 'Version Name',
        updatedAt: 'Updated',
        createdAt: 'Created',
      },
    },
    section: {
      name: 'Section |||| Sections',
      fields: {
        title: 'Title',
        introduction: 'Introduction',
        updatedAt: 'Updated',
        createdAt: 'Created',
      },
    },
    subsection: {
      name: 'Subsection |||| Subsections',
      fields: {
        title: 'Title',
        content: 'Content',
        updatedAt: 'Updated',
        createdAt: 'Created',
      },
    },
    notification: {
      name: 'Notification |||| Notifications',
      fields: {
        title: 'Title',
        message: 'Message',
        departmentId: 'Department',
        userAcknowledged: 'User Acknowledged?',
      },
    },
    viewMeta: {
      fields: {
        logoUrl: 'Logo',
        primaryColor: 'Primary Color',
        secondaryColor: 'Secondary Color',
      },
    },
    inquiryType: {
      name: 'Inquiry Type |||| Inquiry Types',
      fields: {
        title: 'Title',
        introduction: 'Introduction',
        updatedAt: 'Updated',
        createdAt: 'Created',
      },
    },
  },
};
