import * as React from 'react';
import Box from '@material-ui/core/Box';
import { SimpleForm, TextInput, useTranslate } from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import { CompanyRecord } from '../interfaces/company-record.interface';
import { DetailsHeader } from '../../../components/details-header';
import { LogoUploadDrawer } from './logo-upload.drawer';

export const CompanyEditForm = (props: { record: CompanyRecord } & any) => {
  const { record } = props;

  const translate = useTranslate();

  return (
    <SimpleForm {...props} redirect="edit">
      <DetailsHeader>Company Details</DetailsHeader>

      <div style={{ width: '100%' }}>
        <Box
          p={1}
          display="flex"
          flexGrow={1}
          flexWrap="wrap"
          flexDirection="row"
        >
          <Box p={2}>
            <LogoUploadDrawer {...props} record={record} />
          </Box>
          <Box p={2} flexGrow={1}>
            <Box>
              <TextInput source="name" fullWidth />
            </Box>
            <Box>
              <TextInput
                label={translate('resources.company.fields.viewMeta.videoUrl')}
                fullWidth
                source="viewMeta.videoUrl"
              />
            </Box>
          </Box>
          <Box p={2}>
            <Box>
              <ColorInput
                label={translate(
                  'resources.company.fields.viewMeta.primaryColor'
                )}
                filled
                fullWidth
                source="viewMeta.primaryColor"
              />
            </Box>
            <Box>
              <ColorInput
                label={translate(
                  'resources.company.fields.viewMeta.secondaryColor'
                )}
                filled
                fullWidth
                source="viewMeta.secondaryColor"
              />
            </Box>
          </Box>
          <Box flexGrow={1} p={2}>
            <TextInput
              multiline
              rows={6}
              rowsMax={12}
              source="welcomeText"
              fullWidth
            />
          </Box>
        </Box>
      </div>
    </SimpleForm>
  );
};
