import * as React from 'react';
import {
  CreateButton,
  ReferenceManyField,
  ChipField,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { SubListContent } from '../../../components/sub-list-content';
import { CustomListTopToolbar } from '../../../components/custom-list-top-toolbar';
import BulkDeleteUndoableButton from '../../../components/bulk-delete-undoable-button';

const useStyles = makeStyles({
  root: {
    marginTop: '1rem',
  },
});

const UserFilter = (props: any) => {
  return (
    <Box pl={2} pr={2}>
      <Filter {...props}>
        <TextInput label="Name" source="name" alwaysOn />
      </Filter>
    </Box>
  );
};

const CreateDepartmentButton = (props: any) => {
  return (
    <CreateButton
      component={Link}
      to={{
        pathname: '/department/create',
        state: { record: { companyId: props.companyId } },
      }}
    >
      Add New Department
    </CreateButton>
  );
};

const ListEmpty = (props: any) => (
  <Box textAlign="center" m={1}>
    <Typography>No departments have been created for this company.</Typography>
    <CreateDepartmentButton {...props} />
  </Box>
);

const MemberCountField = (props: { total?: number }) => (
  <ChipField record={{ total: props.total }} source="total" />
);

export const CompanyDepartmentList = (props: any) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      basePath="/department"
      resource="department"
      hasCreate={true}
      filter={{ 'companyId||$eq': props.id }}
      className={classes.root}
      title={<span />}
      actions={
        <CustomListTopToolbar
          createButton={<CreateDepartmentButton companyId={props.id} />}
        />
      }
      filters={<UserFilter />}
      empty={<ListEmpty companyId={props.id} />}
      bulkActionButtons={<BulkDeleteUndoableButton />}
    >
      <SubListContent rowClick="edit">
        <TextField source="name" />
        <ReferenceManyField
          label="Members"
          reference="user"
          target="departmentId"
          sortable={false}
          textAlign="center"
        >
          <MemberCountField />
        </ReferenceManyField>
        <EditButton label="" textAlign="right" />
      </SubListContent>
    </List>
  );
};
