import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { DeleteWithConfirmButton, SaveButton, Toolbar } from 'react-admin';
import { DepartmentRecord } from '../interfaces/department-record.interface';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const DepartmentEditToolbar = (props: { record?: DepartmentRecord }) => {
  const { record } = props;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {record && record.id && (
        <DeleteWithConfirmButton
          undoable={false}
          redirect={`/company/${record?.companyId ?? ''}`}
          confirmTitle="messages.department.deleteConfirmTitle"
          confirmContent="messages.department.deleteConfirmContent"
        />
      )}
    </Toolbar>
  );
};
