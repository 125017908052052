import * as React from 'react';
import {
  TextInput,
  CreateController,
  CreateView,
  SimpleForm,
  required,
} from 'react-admin';
import { DetailsHeader } from '../../../components/details-header';
import { SectionRecord } from '../interfaces/section-record.interface';
import { SectionEditActions, SectionTitle } from '../components';

const SectionCreateForm = (props: { record?: SectionRecord }) => {
  const [title, setHeader] = React.useState('');
  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeader(e.target.value);
  };

  return (
    <SimpleForm {...props} redirect="edit">
      <DetailsHeader item={title} itemEmpty="New">
        Section -&nbsp;
      </DetailsHeader>
      <TextInput
        source="title"
        validate={[required()]}
        onChange={onTitleChange}
      />
      <TextInput
        source="introduction"
        multiline
        rows={5}
        rowsMax={10}
        fullWidth
      />
    </SimpleForm>
  );
};

export const SectionCreateView = (props: any) => (
  <CreateController {...props} successMessage="messages.section.created">
    {(controllerProps: any) => {
      const { record } = controllerProps;
      return (
        <CreateView
          {...controllerProps}
          title={<SectionTitle />}
          actions={<SectionEditActions record={record} />}
        >
          <SectionCreateForm />
        </CreateView>
      );
    }}
  </CreateController>
);
