import * as React from 'react';
import { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  useMutation,
} from 'react-admin';
import { UserRecord } from '../interfaces';
import { DetailsHeader } from '../../../components/details-header';
import { UserEditToolbar } from './user-edit-toolbar';
import { Spacer } from '../../../components/spacer';
import { InviteButton } from './invite-button';

export const UserEditForm = (props: { record: UserRecord } & any) => {
  const { record } = props;

  const [newFullName, setFullName] = React.useState<Record<string, string>>({
    fullName: record?.id ? `${record?.firstName} ${record?.lastName}` : '',
    partialFn: '',
    partialLn: '',
  });

  const onFnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFullName({
      ...newFullName,
      fullName: e.target.value + ' ' + newFullName.partialLn,
      partialFn: e.target.value,
    });
  };

  const onLnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFullName({
      ...newFullName,
      fullName: newFullName.partialFn + ' ' + e.target.value,
      partialLn: e.target.value,
    });
  };

  const [
    mutateEmailValidation,
    { loading: loadingEmailValidation },
  ] = useMutation();
  const validateForm = useCallback(
    createUserFormValidator(record, mutateEmailValidation),
    [record]
  );

  return (
    <SimpleForm
      {...props}
      toolbar={<UserEditToolbar record={record} />}
      redirect="edit"
      validate={validateForm}
      validateOnBlur={true}
    >
      <Box
        flexDirection="row"
        width="100%!important"
        display="flex"
        justifyContent="space-between"
      >
        <DetailsHeader item={newFullName.fullName} itemEmpty="New">
          {'User - '}
        </DetailsHeader>
        {record?.id && <InviteButton record={record} />}
      </Box>

      <Spacer />
      <div style={{ width: '100%' }}>
        <Box display="flex">
          <Box flex={1}>
            <TextInput
              source="firstName"
              validate={[required()]}
              fullWidth
              onChange={onFnChange}
            />
          </Box>
          <Spacer />
          <Box flex={1}>
            <TextInput
              source="lastName"
              validate={[required()]}
              fullWidth
              onChange={onLnChange}
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1}>
            <ReferenceInput
              source="departmentId"
              reference="department"
              filter={{ 'companyId||$eq': record.companyId }}
              sort={{ field: 'name', order: 'ASC' }}
              validate={[required()]}
            >
              <SelectInput fullWidth optionText="name" />
            </ReferenceInput>
          </Box>
          <Spacer />
          <Box flex={1}>
            <TextInput
              source="email"
              validate={[required()]}
              disabled={loadingEmailValidation}
              fullWidth
            />
          </Box>
        </Box>
      </div>
    </SimpleForm>
  );
};

//
// Helpers
//

function createUserFormValidator(
  record: UserRecord,
  mutateEmailCallback: Function
) {
  // return callback with scoped mutate callback
  return (fields: { email: string }) => {
    // did we get an email field?
    if (fields?.email) {
      // yes, build up the promise
      return new Promise(resolve => {
        // build up the filter
        const filter: {
          'email||$eq': string;
          'id||$ne'?: string;
        } = {
          'email||$eq': fields.email,
        };

        // get a record id?
        if (record?.id) {
          filter['id||$ne'] = record.id;
        }

        // call the mutator
        mutateEmailCallback(
          {
            type: 'getList',
            resource: 'user',
            payload: {
              filter,
              pagination: {
                page: 1,
                perPage: 1,
              },
            },
          },
          {
            onSuccess: ({ data }: { data: UserRecord[] }) => {
              // did we get a result?
              if (Array.isArray(data) && data.length >= 1) {
                // yes, RESOLVE error
                return resolve({ email: 'Email address already exists.' });
              } else {
                // no, resolve void
                return resolve();
              }
            },
            onFailure: () => {
              // yes, we are RESOLVING on error here
              return resolve({
                email: 'Fatal error while checking username uniqueness',
              });
            },
          }
        );
      });
    } else {
      // nothing we want to validate
      return;
    }
  };
}
