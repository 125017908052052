import * as React from 'react';
import {
  CreateButton,
  DateField,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { SubListHeader } from '../../../components/sub-list-header';
import { SubListContent } from '../../../components/sub-list-content';
import { CustomListTopToolbar } from '../../../components/custom-list-top-toolbar';
import { OrderButtons } from '../../../components/order-buttons';

const useStyles = makeStyles({
  root: {
    marginTop: '1rem',
  },
});

const SectionFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="title" alwaysOn />
    </Filter>
  );
};

const CreateSectionButton = (props: any) => {
  return (
    <CreateButton
      component={Link}
      to={{
        pathname: '/section/create',
        state: { record: { handbookId: props.handbookId } },
      }}
    >
      Add New Section
    </CreateButton>
  );
};

const ListEmpty = (props: any) => (
  <Box textAlign="center" m={1}>
    <Typography>No sections have been created for this handbook.</Typography>
    <CreateSectionButton {...props} />
  </Box>
);

export const HandbookSectionList = (props: any) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      successMessage="messages.company_updated"
      basePath="/section"
      resource="section"
      hasCreate={true}
      className={classes.root}
      title={<span />}
      actions={
        <CustomListTopToolbar
          createButton={<CreateSectionButton handbookId={props.id} />}
        />
      }
      filter={{ 'handbookId||$eq': props.id }}
      filters={<SectionFilter />}
      sort={{ field: 'sortPriority', order: 'ASC' }}
      empty={<ListEmpty handbookId={props.id} />}
    >
      <SubListContent
        rowClick="edit"
        header={<SubListHeader>Sections</SubListHeader>}
      >
        <TextField source="title" sortable={false} />
        <DateField source="updatedAt" showTime sortable={false} />

        <OrderButtons source="sortPriority" />
        <EditButton label="" textAlign="right" />
      </SubListContent>
    </List>
  );
};
