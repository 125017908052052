import React from 'react';
import { BulkDeleteButton } from 'react-admin';

const BulkDeleteUndoableButton = (props:any) => (
  <React.Fragment>
    <BulkDeleteButton {...props} undoable={false}/>
  </React.Fragment>
);

export default BulkDeleteUndoableButton;
