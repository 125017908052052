import * as React from 'react';
import { SaveButton, Toolbar } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const NotificationCreateToolbar = (props: any) => {
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton icon={<SendIcon />} label="Send" />
    </Toolbar>
  );
};
