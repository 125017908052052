import * as React from 'react';
import { Button, useMutation, useNotify } from 'react-admin';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import { UserRecord } from '../interfaces';

export const InviteButton = (props: { record: UserRecord }) => {
  const { record } = props;

  const notify = useNotify();

  const [send, { loading }] = useMutation(
    {
      type: 'createRaw',
      resource: 'invitation',
      payload: { data: { userId: record.id } },
    },
    {
      onSuccess: () => {
        notify('Invitation sent!');
      },
      onFailure: (error: Error) =>
        notify(`Invitation delivery error: ${error.message}`, 'warning'),
    }
  );
  return (
    <Button
      label="Send Invite"
      variant="outlined"
      startIcon={<EmailIcon />}
      onClick={send}
      disabled={loading}
    />
  );
};
