import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  titleMain: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}));

export const CustomAppBar = (props: any) => {
  const classes: any = useStyles();

  return (
    <AppBar {...props} color="primary">
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Typography
        variant="h6"
        color="inherit"
        classes={{
          root: classes.title,
          h6: classes.titleMain,
        }}
      >
        {props.title}
      </Typography>
    </AppBar>
  );
};
