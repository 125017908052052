import React, { useCallback, useState } from 'react';
import { ImageInput } from 'react-admin';
import { ImageCropperField } from '../image-cropper/image-cropper-field';
import { ImageCropperInputProps } from './interfaces/image-cropper-input.props';

interface CroppedFile {
  name: string;
  rawFile: File;
  croppedFile: File | undefined;
}

const transformFile = (file: File): CroppedFile => {
  return {
    name: file.name,
    rawFile: file,
    croppedFile: undefined,
  };
};

export const ImageCropperInput = (props: ImageCropperInputProps) => {
  // grab the props
  const { cropperOptions, ...rest } = props;

  const [file, setFile] = useState<CroppedFile>();

  const transformFiles = (inputFile: File | File[]) => {
    if (inputFile) {
      if (Array.isArray(inputFile)) {
        const mappedFile = transformFile(inputFile[0]);
        setFile(mappedFile);
        return mappedFile;
      } else {
        const mappedFile = transformFile(inputFile);
        setFile(mappedFile);
        return mappedFile;
      }
    }

    return;
  };

  const onInputCreateCrop = useCallback(
    (croppedFile: File) => {
      // find the original file object and set the cropper src
      if (file) {
        file.croppedFile = croppedFile;
      }
    },
    [file]
  );

  // set on the cropper options
  cropperOptions.onCreateCrop = onInputCreateCrop;

  return (
    <ImageInput {...rest} multiple={false} parse={transformFiles}>
      <ImageCropperField
        cropperOptions={cropperOptions}
        source="src"
        title="title"
      />
    </ImageInput>
  );
};
