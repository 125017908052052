import { makeStyles } from '@material-ui/core';

export const inquiryTypeRichTextStyles = makeStyles({
  richText: {
    '& div.ql-container.ql-snow': {
      height: '100%',
    },
    '& div.ql-toolbar.ql-snow': {
      margin: 0,
      padding: '.5rem',
      border: '1px solid lightgrey',
    },
    '& div.ql-toolbar.ql-snow + div.ql-container.ql-snow': {
      border: '1px solid lightgrey',
      borderTop: 'none',
    },
    '& div.ql-editor': {
      minHeight: '20rem',
    },
  },
});
