import React from 'react';

import { useNotify, Notification, useRedirect } from 'react-admin';
import { useForm } from 'react-hook-form';
import { Login } from 'react-admin';
import { withStyles } from '@material-ui/styles';
import { loginStyles, formStyles } from './styles';
import { TextField, Button, Typography } from '@material-ui/core';
import * as yup from 'yup';
import { AuthService } from '../../libs/sdk';
import { Spacer } from '../../components/spacer';

const schema = yup.object().shape({
  email: yup.string().required().email(),
});

const ForgotPasswordScreen: React.FC<any> = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
  });

  const onSubmit = async (data: Record<string, any>) => {
    try {
      const { email } = data;
      await AuthService.authResetPassword({ body: { email, isAdmin: true } });
      notify('Forgot password request sent.');
      redirect('/login');
    } catch (err) {
      notify('Could not send reset password request', 'warning');
      console.error(err);
    }
  };

  return (
    <Login {...props}>
      <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
        Forgot Password?
      </Typography>

      <Spacer />

      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="email"
          id="email"
          error={!!errors.email}
          inputRef={register}
          helperText={errors.email && errors.email.message}
          label="Email Address"
          fullWidth
        />
        <Spacer space={40} />
        <Button
          style={formStyles.button}
          type="submit"
          variant="contained"
          color="primary"
        >
          Send
        </Button>
      </form>
      <Notification />
    </Login>
  );
};

export default withStyles(loginStyles)(ForgotPasswordScreen);
