interface Credentials {
  username: string;
  password: string;
}

const isUnathorized = 401 || 403;

const signInReq = (credentials: Credentials) => {
  return new Request(`${process.env.REACT_APP_API_URL}/auth/signin`, {
    method: 'POST',
    body: JSON.stringify({ ...credentials, assertRoles: ['SuperAdmin'] }),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
};
const storeTokens = (tokens: any) => {
  localStorage.setItem('accessToken', tokens.accessToken);
  localStorage.setItem('refreshToken', tokens.refreshToken);
};

const removeTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

const authProvider = {
  login: async (credentials: Credentials) => {
    const response = await fetch(signInReq(credentials));

    const { status } = response;

    if (status === isUnathorized || status === 400) {
      const body = await response.json();
      if (body?.message) {
        const message = Array.isArray(body?.message) ? body?.message.join('') : body?.message; 
        throw new Error(message);
      } else {
        throw new Error('An unknown error has occurred');
      }
    }

    if (status < 200 || status >= 300) {
      throw new Error(response.statusText);
    }

    const tokens = await response.json();
    storeTokens(tokens);
  },
  logout: () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    // localStorage.removeItem("permissions");
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem('refreshToken') ? Promise.resolve() : Promise.reject(),
  checkError: (error: any) => {
    const status = error.status;

    if (status === isUnathorized) {
      removeTokens();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: (params: any) => Promise.resolve(),
};

export default authProvider;
