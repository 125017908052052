import * as React from 'react';
import { CreateController, CreateView } from 'react-admin';
import { InquiryTypeRecord } from '../interfaces/inquiry-type-record.interface';
import { InquiryTypeEditActions } from '../components/inquiry-type-edit-actions';
import { InquiryTypeEditForm } from '../components/inquiry-type-edit-form';

export const InquiryTypeCreateView = (props: {
  id: string;
  data: InquiryTypeRecord;
}) => (
  <CreateController
    {...props}
    undoable={false}
    successMessage="messages.inquiryType.created"
  >
    {(controllerProps: any) => {
      const { record } = controllerProps;
      return (
        <CreateView
          {...controllerProps}
          title={<span />}
          actions={<InquiryTypeEditActions record={record} />}
        >
          <InquiryTypeEditForm id={props.id} />
        </CreateView>
      );
    }}
  </CreateController>
);
