import React, { useState, useCallback, useRef } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Box from '@material-ui/core/Box';
import { ImageCropperProps } from './interfaces/image-cropper.props';

export function ImageCropper(props: ImageCropperProps) {
  const { src, fileName, onCreateCrop, ...reactCropProps } = props;

  const imgRef = useRef<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop | undefined>(reactCropProps?.crop);
  const [previewUrl, setPreviewUrl] = useState<string>('');

  const onLoad = useCallback(img => {
    if (null !== img) {
      imgRef.current = img;
    }
  }, []);

  const createCropPreview = useCallback(
    async (
      image: HTMLImageElement,
      crop: Crop,
      fileName: string,
      oldPreviewUrl
    ): Promise<File> => {
      const { width = 1, height = 1, x = 1, y = 1 } = crop;

      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(
          image,
          x * scaleX,
          y * scaleY,
          width * scaleX,
          height * scaleY,
          0,
          0,
          width,
          height
        );
      }

      return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
          if (blob) {
            const file = new File([blob], fileName);
            if (onCreateCrop) {
              onCreateCrop(file);
            }
            window.URL.revokeObjectURL(oldPreviewUrl);
            setPreviewUrl(window.URL.createObjectURL(file));
            return resolve();
          } else {
            return reject(new Error('Canvas is empty'));
          }
        }, 'image/png');
      });
    },
    [onCreateCrop]
  );

  const makeClientCrop = useCallback(
    async (crop: Crop) => {
      if (null !== imgRef.current) {
        await createCropPreview(imgRef.current, crop, fileName, previewUrl);
      }
    },
    [createCropPreview, fileName, previewUrl]
  );

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box m={1} p={2} width="50%" bgcolor="grey.400">
        <ReactCrop
          {...reactCropProps}
          src={src}
          crop={crop}
          onImageLoaded={onLoad}
          onChange={c => setCrop(c)}
          onComplete={makeClientCrop}
        />
      </Box>
      {previewUrl && (
        <Box display="flex" width="50%" justifyContent="center">
          <Box m={1} p={2} bgcolor="grey.400">
            <img alt="Crop preview" src={previewUrl} />
          </Box>
        </Box>
      )}
    </Box>
  );
}
