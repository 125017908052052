import React from 'react';
import { Admin, Resource } from 'react-admin';
import company from './modules/company/company.resource';
import user from './modules/user/user.resource';
import department from './modules/department/department.resource';
import handbook from './modules/handbook/handbook.resource';
import section from './modules/section/section.resource';
import subsection from './modules/subsection/subsection.resource';
import companyNotification from './modules/companyNotification/company-notification.resource';
import handbookNotification from './modules/handbookNotification/handbook-notification.resource';
import inquiryType from './modules/inquiryType/inquiry-type.resource';
import { httpClient } from './libs/http';
import { createMuiTheme } from '@material-ui/core/styles';
import LoginScreen from './screens/public-screens/login-screen';
import authProvider from './providers/auth.provider';
import crudProvider from './providers/crud-data.provider';
import i18nProvider from './i18n';
import { CustomLayout } from './components/custom-layout';
import customRoutes from './routes';
import { createBrowserHistory as createHistory } from 'history';

const themeOptions = {
  palette: {
    primary: { main: '#274c68' },
    secondary: { main: '#F3BD5D' },
  },
  overrides: {
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#3cb450',
      },
      message: {
        color: 'black',
      },
    },
  },
};

const theme = createMuiTheme(themeOptions);

const dataProvider = crudProvider(
  process.env.REACT_APP_API_URL ?? 'http://localhost:3000',
  httpClient,
  { alwaysPaginate: true }
);

const history = createHistory();

function App() {
  return (
    <Admin
      i18nProvider={i18nProvider}
      loginPage={LoginScreen}
      theme={theme}
      history={history}
      customRoutes={customRoutes}
      dataProvider={dataProvider}
      authProvider={authProvider}
      title="In.Hand.Book"
      layout={CustomLayout}
    >
      <Resource name="company" {...company} />
      <Resource name="user" {...user} />
      <Resource name="department" {...department} />
      <Resource name="handbook" {...handbook} />
      <Resource name="section" {...section} />
      <Resource name="subsection" {...subsection} />
      <Resource name="notification/sendCompany" {...companyNotification} />
      <Resource name="notification/sendHandbook" {...handbookNotification} />
      <Resource name="invitation" />
      <Resource name="inquiry-type" {...inquiryType} />
    </Admin>
  );
}

export default App;
