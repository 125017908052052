import * as React from 'react';
import { Datagrid } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  dgHeader: {
    '& thead th': {
      fontWeight: 'bold',
    },
  },
});

export const SubListContent = (props: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props?.header}
      <Datagrid {...props} className={classes.dgHeader} />
    </React.Fragment>
  );
};
