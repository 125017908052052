import { Error, Loading, TopToolbar, useQueryWithStore } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { HandbookOwnable } from '../interfaces/handbook-ownable.interface';

const useStyles = makeStyles({
  root: {
    justifyContent: 'flex-start',
  },
  backButton: {
    fontSize: '1em',
    textTransform: 'none',
  },
});

export const HandbookResourceEditActionsToolbar = (props: {
  record: HandbookOwnable;
}) => {
  const { record } = props;

  const { data, loading, error } = useQueryWithStore({
    type: 'getOne',
    resource: 'handbook',
    payload: { id: record.handbookId },
  });

  const classes = useStyles();

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return <div />;

  return (
    <TopToolbar className={classes.root}>
      <Button
        className={classes.backButton}
        component={Link}
        to={'/handbook/' + record.handbookId}
      >
        <ArrowBackIosIcon /> {data.title} Details
      </Button>
    </TopToolbar>
  );
};
