import React, { useState } from 'react';
import { ImageCropper } from './image-cropper';
import { ImageCropperFieldProps } from './interfaces/image-cropper-field.props';

export const ImageCropperField = (props: ImageCropperFieldProps) => {
  const { record, cropperOptions } = props;

  // cropper states
  const [cropperSrc, setCropperSrc] = useState<string>('');

  if (!record) {
    return <div>Unable to load file, missing record prop</div>;
  }

  if (!cropperSrc && record.rawFile) {
    processRawFile(record.rawFile, setCropperSrc);
  }

  // determine final file name
  const finalFileName = cropperOptions.fileName ?? record.rawFile.name;

  return (
    <ImageCropper
      src={cropperSrc}
      fileName={finalFileName}
      {...cropperOptions}
    />
  );
};

function processRawFile(file: File, stateSetter: Function): void {
  const reader = new FileReader();

  reader.addEventListener('load', () => {
    if ('string' === typeof reader.result) {
      return stateSetter(reader.result);
    }
  });

  reader.readAsDataURL(file);
}
