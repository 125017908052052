import * as React from 'react';
import { EditController, EditView } from 'react-admin';
import { UserEditActions, UserEditForm } from '../components';
import { UserRecord } from '../interfaces/user-record.interface';

export const UserEditView = (props: { id: string; data: UserRecord }) => (
  <EditController
    {...props}
    undoable={false}
    successMessage="messages.user.updated"
  >
    {(controllerProps: any) => {
      const { record } = controllerProps;
      return (
        <EditView
          {...controllerProps}
          undoable={false}
          title={<span />}
          actions={<UserEditActions record={record} />}
        >
          <UserEditForm id={props.id} />
        </EditView>
      );
    }}
  </EditController>
);
