import { fetchUtils } from 'react-admin';
import jwtDecode from 'jwt-decode';
import { storeTokens } from './tokens';

export const httpClient = async (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  await hydrateToken();
  const accessToken: any = localStorage.getItem('accessToken');

  options.headers.set('Authorization', `Bearer ${accessToken}`);
  return fetchUtils.fetchJson(url, options);
};

const refreshTokenReq = () => {
  return new Request(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
    method: 'POST',
    body: JSON.stringify({
      refreshToken: localStorage.getItem('refreshToken'),
    }),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
};

const hasTokenExpired = (jwtToken: string, buffer = 5000) => {
  const jwt: any = jwtDecode(jwtToken);
  if (!jwt.exp) {
    return true;
  }

  if (Date.now() >= jwt.exp * 1000) {
    return true;
  } else {
    return false;
  }
};

// Holding execution until others complete
let refreshingToken = false;

export const hydrateToken = async (rerun = false) => {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) {
    throw new Error('Your session has expired.');
  }

  // Return if its not expired
  if (!hasTokenExpired(accessToken)) return;

  try {
    if (refreshingToken) {
      // If its already refreshing the token pause execution
      // Call function again
      await sleep(100);
      await hydrateToken(true);
    } else {
      refreshingToken = true;
      await goRefreshToken();
      refreshingToken = false;
    }
  } catch (err) {
    throw err;
  }
};

const goRefreshToken = async () => {
  const response = await fetch(refreshTokenReq());
  const tokens = await response.json();
  storeTokens(tokens);
};

const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};
