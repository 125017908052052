import * as React from 'react';
import {
  TextInput,
  EditController,
  EditView,
  SimpleForm,
  required,
} from 'react-admin';
import { SectionRecord } from '../interfaces/section-record.interface';
import { DetailsHeader } from '../../../components/details-header';
import {
  SectionEditToolbar,
  SectionEditActions,
  SectionTitle,
  SectionSubsectionList,
} from '../components';

const SectionEditForm = (props: { record?: SectionRecord }) => {
  const { record } = props;
  const [title, setHeader] = React.useState(record?.title);
  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeader(e.target.value);
  };

  return (
    <SimpleForm
      {...props}
      redirect="edit"
      toolbar={<SectionEditToolbar record={record} />}
    >
      <DetailsHeader item={title} itemDefault={record?.title}>
        Section -&nbsp;
      </DetailsHeader>
      <TextInput
        source="title"
        validate={[required()]}
        onChange={onTitleChange}
      />
      <TextInput
        source="introduction"
        multiline
        rows={5}
        rowsMax={10}
        fullWidth
      />
    </SimpleForm>
  );
};

export const SectionEditView = (props: { id: string; data: SectionRecord }) => (
  <React.Fragment>
    <EditController
      {...props}
      undoable={false}
      successMessage="messages.section.updated"
    >
      {(controllerProps: any) => {
        const { record } = controllerProps;
        return (
          <EditView
            {...controllerProps}
            undoable={false}
            title={<SectionTitle />}
            actions={<SectionEditActions record={record} />}
          >
            <SectionEditForm />
          </EditView>
        );
      }}
    </EditController>
    <SectionSubsectionList id={props.id} />
  </React.Fragment>
);
