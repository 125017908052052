import * as React from 'react';
import { CompanyResourceEditActionsToolbar } from '../../company/components/company-resource-edit-actions-toolbar';
import { UserRecord } from '../interfaces';

export const UserEditActions = (props: { record: UserRecord }) => {
  const { record } = props;

  if (record) {
    return <CompanyResourceEditActionsToolbar record={record} />;
  } else {
    return <div />;
  }
};
