import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

interface SubListHeaderOptions {
  children: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '.5rem',
  },
}));

export const SubListHeader = (props: SubListHeaderOptions) => {
  const classes = useStyles();

  return (
    <Box p={2}>
      <Typography variant="h4" color="inherit" className={classes.root}>
        {props.children}
      </Typography>
    </Box>
  );
};
