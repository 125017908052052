import { Error, Loading, TopToolbar, useQueryWithStore } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { CompanyOwnable } from '../interfaces/company-ownable.interface';

const useStyles = makeStyles({
  root: {
    justifyContent: 'space-between',
  },
  backButton: {
    fontSize: '1em',
    textTransform: 'none',
  },
});

export const CompanyResourceEditActionsToolbar = (props: {
  record: CompanyOwnable;
  extraButton?: any;
}) => {
  const { record, extraButton } = props;

  const { data, loading, error } = useQueryWithStore({
    type: 'getOne',
    resource: 'company',
    payload: { id: record.companyId },
  });

  const classes = useStyles();

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return <div />;

  return (
    <TopToolbar className={classes.root}>
      <Button
        className={classes.backButton}
        component={Link}
        to={'/company/' + record.companyId}
      >
        <ArrowBackIosIcon /> {data.name} Details
      </Button>
      {extraButton}
    </TopToolbar>
  );
};
