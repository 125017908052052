import * as React from 'react';
import { CreateController, CreateView } from 'react-admin';
import { DepartmentRecord } from '../interfaces/department-record.interface';
import { DepartmentEditActions } from '../components/department-edit-actions';
import { DepartmentEditForm } from '../components/department-edit-form';

export const DepartmentCreateView = (props: {
  id: string;
  data: DepartmentRecord;
}) => (
  <CreateController
    {...props}
    undoable={false}
    successMessage="messages.department.created"
  >
    {(controllerProps: any) => {
      const { record } = controllerProps;
      return (
        <CreateView
          {...controllerProps}
          title={<span />}
          actions={<DepartmentEditActions record={record} />}
        >
          <DepartmentEditForm id={props.id} />
        </CreateView>
      );
    }}
  </CreateController>
);
