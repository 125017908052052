import React from 'react';
import { Route } from 'react-router-dom';
import {
  ForgotPasswordScreen,
  ResetPasswordScreen,
} from './screens/public-screens';

export default [
  <Route path="/forgot-password" component={ForgotPasswordScreen} noLayout />,
  <Route
    path="/reset-password/:token"
    component={ResetPasswordScreen}
    noLayout
  />,
];
