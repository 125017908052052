import * as React from 'react';
import { CompanyResourceEditActionsToolbar } from '../../company/components/company-resource-edit-actions-toolbar';
import { InquiryTypeRecord } from '../interfaces/inquiry-type-record.interface';

export const InquiryTypeEditActions = (props: {
  record: InquiryTypeRecord;
}) => {
  const { record } = props;

  if (record) {
    return <CompanyResourceEditActionsToolbar record={record} />;
  } else {
    return <div />;
  }
};
