import React, { useCallback, useState } from 'react';
import {
  Button,
  SaveButton,
  SimpleForm,
  Toolbar,
  LinearProgress,
  useUpdate,
  useNotify,
  useRedirect,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import Drawer from '@material-ui/core/Drawer';
import {
  CompanyRecord,
  ViewMetaLogo,
} from '../interfaces/company-record.interface';
import S3Upload from 'react-s3-uploader/s3upload';
import { ReactS3UploaderProps, S3Response } from 'react-s3-uploader';
import { ImageCropperInput } from '../../../components/image-cropper/image-cropper-input';
import { CompanyAvatar } from '../../../components/company-avatar';

const toolBarStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const imageInputStyles = makeStyles({
  dropZone: {
    height: '5em',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.19)',
  },
});

const drawerStyles = makeStyles({
  root: {
    '& .simple-form': {
      width: '90%',
    },
  },
  paper: {
    width: '50em',
  },
});

const UploadLogoToolbar = (props: { setShowDrawer: Function }) => {
  const { setShowDrawer } = props;
  const classes = toolBarStyles();

  return (
    <Toolbar {...props} className={classes.root}>
      <SaveButton label="Save" submitOnEnter={false} />
      <Button
        icon={<CancelIcon />}
        label="Cancel"
        onClick={() => setShowDrawer(false)}
      />
    </Toolbar>
  );
};

export const LogoUploadDrawer = (props: { record: CompanyRecord }) => {
  const { record } = props;

  // drawer state
  const [showDrawer, setShowDrawer] = React.useState(false);

  // upload progress
  const [isUploading, setIsLoading] = useState<boolean>(false);
  const [uploadPercent, setUploadPercent] = useState<number>(0);

  // effects
  const notify = useNotify();
  const redirect = useRedirect();
  const [update] = useUpdate('company');

  // styles
  const drawerClasses = drawerStyles();
  const imageInputClasses = imageInputStyles();

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setShowDrawer(open);
  };

  const doSaveCompany = useCallback(
    (file: ViewMetaLogo) => {
      update(
        {
          payload: {
            id: record.id,
            data: {
              viewMeta: {
                ...record?.viewMeta,
                logoUrl: [file],
              },
            },
          },
        },
        {
          action: 'UPLOAD_LOGO',
          undoable: false,
          onSuccess: ({ data }: { data: CompanyRecord }) => {
            notify(`${data.name} logo updated`, 'info');
            setShowDrawer(false);
            redirect('edit', '/company', data.id);
          },
          onFailure: (error: Error) => {
            notify(`Updating logo failed: ${error.message}`, 'warning');
          },
        }
      );
    },
    [notify, record, redirect, update]
  );

  const onFileProgress = useCallback((percentage: number) => {
    if (percentage === 0) {
      return setIsLoading(true);
    }
    if (percentage === 100) {
      return setIsLoading(false);
    }
    setUploadPercent(percentage);
  }, []);

  const onFileUploaded = useCallback(
    (file: S3Response) => {
      const inputFile: ViewMetaLogo = {
        name: file.filename,
        url: file.fileKey,
      };
      doSaveCompany(inputFile);
    },
    [doSaveCompany]
  );

  const doUploadFile = useCallback(
    (croppedFile, onFileUploaded) => {
      const s3UploadOptions: ReactS3UploaderProps = {
        files: [croppedFile],
        signingUrlMethod: 'GET',
        accept: '*/*',
        onProgress: onFileProgress,
        onFinishS3Put: onFileUploaded,
        uploadRequestHeaders: { 'x-amz-acl': 'private' },
        signingUrlWithCredentials: false,
        signingUrl: process.env.REACT_APP_API_URL + '/uploads/sign',
        s3path: 'public',
      };

      // tell s3 uploader to do it's thing
      new S3Upload(s3UploadOptions);
    },
    [onFileProgress]
  );

  const save = useCallback(
    (finalValues: any) => {
      if (finalValues?.image?.croppedFile) {
        doUploadFile(finalValues.image.croppedFile, onFileUploaded);
      }
    },
    [doUploadFile, onFileUploaded]
  );

  return (
    <>
      <CompanyAvatar record={record} size="large" />
      <Button label="Upload Logo" onClick={toggleDrawer(true)} />
      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={toggleDrawer(false)}
        classes={drawerClasses}
      >
        <Box display="flex" justifyContent="center">
          <SimpleForm
            save={save}
            toolbar={<UploadLogoToolbar setShowDrawer={setShowDrawer} />}
          >
            <ImageCropperInput
              source="image"
              label="Company Logo"
              placeholder="Drag an image here, or click to upload."
              classes={imageInputClasses}
              cropperOptions={{
                fileName: `${record.id}_${Date.now()}_logo.png`,
                minWidth: 100,
                minHeight: 100,
                maxWidth: 500,
                maxHeight: 500,
                crop: {
                  height: 50,
                  width: 50,
                  unit: '%',
                },
              }}
            />
            {isUploading && <LinearProgress value={uploadPercent} />}
          </SimpleForm>
        </Box>
      </Drawer>
    </>
  );
};
