import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { formStyles, loginStyles } from './styles';
import { Button } from '@material-ui/core';

const CustomLoginForm = withStyles(formStyles)(LoginForm);

const LoginScreen = (props: any) => {
  return (
    <Login {...props}>
      <CustomLoginForm />
      <Button fullWidth href="/forgot-password" onClick={() => {}}>
        Forgot Password
      </Button>
    </Login>
  );
};

export default withStyles(loginStyles)(LoginScreen);
