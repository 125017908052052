import * as React from 'react';
import { HandbookNotification } from '../interfaces/handbook-notification.interface';
import { HandbookResourceEditActionsToolbar } from '../../handbook/components/handbook-resource-edit-actions-toolbar';

export const HandbookNotificationCreateActions = (props: {
  record: HandbookNotification;
}) => {
  const { record } = props;

  if (record) {
    return <HandbookResourceEditActionsToolbar record={record} />;
  } else {
    return <div />;
  }
};
