import * as React from 'react';
import {
  TextInput,
  EditController,
  EditView,
  SimpleForm,
  required,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { SubsectionRecord } from '../interfaces/subsection-record.interface';
import { DetailsHeader } from '../../../components/details-header';
import {
  SubsectionEditActions,
  SubsectionTitle,
  SubsectionEditToolbar,
  subsectionRichTextStyles,
} from '../components';

const SubsectionEditForm = (props: { record?: SubsectionRecord }) => {
  const { record } = props;
  const classes = subsectionRichTextStyles();
  const [title, setHeader] = React.useState(record?.title);
  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeader(e.target.value);
  };

  return (
    <SimpleForm
      {...props}
      toolbar={<SubsectionEditToolbar record={record} />}
      redirect="edit"
    >
      <DetailsHeader item={title} itemDefault={record?.title}>
        Subsection -&nbsp;
      </DetailsHeader>
      <TextInput
        source="title"
        validate={[required()]}
        onChange={onTitleChange}
      />
      <RichTextInput
        label=""
        source="content"
        fullWidth
        formClassName={classes.richText}
      />
    </SimpleForm>
  );
};

export const SubsectionEditView = (props: { data?: SubsectionRecord }) => {
  return (
    <EditController
      {...props}
      undoable={false}
      successMessage="messages.subsection.updated"
    >
      {(controllerProps: any) => {
        const { record } = controllerProps;
        return (
          <EditView
            {...controllerProps}
            undoable={false}
            title={<SubsectionTitle />}
            actions={<SubsectionEditActions record={record} />}
          >
            <SubsectionEditForm />
          </EditView>
        );
      }}
    </EditController>
  );
};
