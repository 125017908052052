import * as React from 'react';
import Button from '@material-ui/core/Button';
import { CompanyResourceEditActionsToolbar } from '../../company/components/company-resource-edit-actions-toolbar';
import { makeStyles } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { Link } from 'react-router-dom';
import { HandbookRecord } from '../interfaces/handbook-record.interface';

const useStyle = makeStyles({
  actionButton: {
    fontSize: '1em',
    textTransform: 'none',
  },
});

const SendButton = (props: { record: HandbookRecord }) => {
  const { record } = props;

  const classes = useStyle();

  return (
    <Button
      className={classes.actionButton}
      component={Link}
      to={{
        pathname: '/notification/sendHandbook/create',
        state: { record: { handbookId: record.id } },
      }}
      startIcon={<SendIcon />}
    >
      Force Acknowledgement
    </Button>
  );
};

export const HandbookEditActions = (props: any) => {
  const { data } = props;

  if (data) {
    return (
      <CompanyResourceEditActionsToolbar
        record={data}
        extraButton={<SendButton record={data} />}
      />
    );
  } else {
    return <div />;
  }
};
