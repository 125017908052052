import * as React from 'react';
import { SimpleForm, TextInput, required } from 'react-admin';
import { DetailsHeader } from '../../../components/details-header';
import { InquiryTypeEditToolbar } from './inquiry-type-edit-toolbar';
import { InquiryTypeRecord } from '../interfaces/inquiry-type-record.interface';
import { Spacer } from '../../../components/spacer';
import RichTextInput from 'ra-input-rich-text';
import { inquiryTypeRichTextStyles } from './inquiry-type-rich-text.styles';

export const InquiryTypeEditForm = (
  props: { record: InquiryTypeRecord } & any
) => {
  const { record } = props;
  const [title, setHeader] = React.useState('');
  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeader(e.target.value);
  };
  const classes = inquiryTypeRichTextStyles();

  return (
    <SimpleForm
      {...props}
      toolbar={<InquiryTypeEditToolbar record={record} />}
      redirect="edit"
    >
      <DetailsHeader item={title} itemDefault={record?.name} itemEmpty="New">
        InquiryType -&nbsp;
      </DetailsHeader>
      <Spacer />
      <TextInput
        source="name"
        validate={[required()]}
        onChange={onTitleChange}
      />
      <Spacer />
      <TextInput
        source="recipientEmail"
        type="email"
        validate={[required()]}
        fullWidth
      />
      <Spacer />
      <RichTextInput
        label=""
        source="content"
        fullWidth
        formClassName={classes.richText}
      />
    </SimpleForm>
  );
};
